import Button from 'components/shared/Buttons/Button'
import { isEmptyArray } from 'utils/arrays'
import useAnalytics from 'hooks/useAnalytics'
import HomepageCarousel from 'components/home/HomepageCarousel'
import styles from './guidescarouselsection.module.scss'
import { TAP_SEE_ALL_GUIDES_INTERACTION_NAME } from 'utils/analytics/interactions'
import { GuideCards } from 'components/shared/cards/types'

const GUIDES_URL = '/guides'

export type GuidesCarouselSectionProps = {
  guides: GuideCards
}

const GuidesCarouselSection: React.FC<GuidesCarouselSectionProps> = ({
  guides = [],
}) => {
  if (isEmptyArray(guides)) {
    return null
  }
  const { fireInteractionEvent } = useAnalytics()
  return (
    <section className={styles.guidesCarouselSection}>
      {/* @ts-expect-error */}
      <HomepageCarousel
        className={styles.guidesCarousel}
        headerDetails={{
          title: 'How-To Guides',
          description:
            'Go in-depth on techniques, ingredient tips, equipment and more.',
          linkUrl: GUIDES_URL,
          trackingLabel: 'guides',
        }}
        cardData={guides}
      />
      <Button
        element="a"
        href={GUIDES_URL}
        className={styles.allGuidesLink}
        onClick={() =>
          fireInteractionEvent({
            name: TAP_SEE_ALL_GUIDES_INTERACTION_NAME,
            label: 'guides',
          })
        }
      >
        See all cooking guides
      </Button>
    </section>
  )
}

export default GuidesCarouselSection
